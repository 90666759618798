export const DATADOG_TOKENS = {
  SERVICE: {
    login: {
      APPLICATION_ID: '93ef9cfa-0967-413e-84c5-e268bc5299e0',
      CLIENT_TOKEN: 'pub4f35cb3335e1d01a49f25d766dede4cb',
    },
    admin: {
      APPLICATION_ID: '1b443c33-0f9e-4b3d-8e7f-edfc5feb5419',
      CLIENT_TOKEN: 'pub9df4a266c148984726d9f4eeae8990bb',
    },
    'voltapp-react': {
      APPLICATION_ID: '671cdf3d-b100-4535-b52d-c9b660b3888f',
      CLIENT_TOKEN: 'pub0cc6f0a21b46fd1b6eb6838f71ec40bb',
    },
    sharemydata: {
      APPLICATION_ID: 'f33974a5-436d-4bc7-85f7-5dd68907a7ed',
      CLIENT_TOKEN: 'pub55dc60e5a9526983c5db89d4bdf37334',
    },
  },
  SITE: 'datadoghq.com',
} as const
