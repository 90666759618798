import styledSystemCss from '@styled-system/css'
import CSS from 'csstype'
import { History } from 'history'
import {
  compose,
  space,
  typography,
  color,
  layout,
  flexbox,
  grid,
  border,
  background,
  position,
  shadow,
  variant,
  system,
  letterSpacing,
} from 'styled-system'
import { Simplify } from 'type-fest'
import { Any, AnyObject } from '@voltus/types'

// Styled system supports arrays of values that map
// to responsive types
export type ResponsiveProp<PropType> = PropType | Array<PropType>

type HTMLProps<El = Element> = {
  id?: string
  value?: Any
  accept?: string
  multiple?: boolean
  disabled?: boolean
  checked?: boolean
  href?: string
  htmlFor?: string
  name?: string
  download?: boolean
  onBlur?: React.FocusEventHandler<El>
  onChange?: React.ChangeEventHandler<El>
  onClick?: React.MouseEventHandler<El>
  onDragEnter?: React.DragEventHandler<El>
  onDragLeave?: React.DragEventHandler<El>
  onDragOver?: React.DragEventHandler<El>
  onDrop?: React.DragEventHandler<El>
  onFocus?: React.FocusEventHandler<El>
  onKeyDown?: React.KeyboardEventHandler<El>
  onKeyUp?: React.KeyboardEventHandler<El>
  onMouseDown?: React.MouseEventHandler<El>
  onMouseEnter?: React.MouseEventHandler<El>
  onMouseLeave?: React.MouseEventHandler<El>
  onMouseMove?: React.MouseEventHandler<El>
  onMouseOut?: React.MouseEventHandler<El>
  onMouseOver?: React.MouseEventHandler<El>
  onMouseUp?: React.MouseEventHandler<El>
  role?: string
  tabIndex?: number
  target?: string
  title?: string
}

type HTMLAnchorProps = {
  target?: string
  href?: string
  rel?: string

  // This prop is techincally a react-router prop
  // but it's fairly common to do <Box as={Link} to="something" />
  // So easiest just to support it here
  to?: History.LocationDescriptor
}

type SelectorPrefix =
  | '&'
  | ':'
  | '#'
  | '.'
  | '&:'
  | '+'
  | '>'
  | '~'
  | '*'
  | '@'
  | '['
export type Selectors = `${SelectorPrefix}${string}`

type StyledPropsProps = {
  alignItems?: ResponsiveProp<CSS.Property.AlignItems>
  alignSelf?: ResponsiveProp<CSS.Property.AlignSelf>
  background?: ResponsiveProp<string>
  backgroundColor?: ResponsiveProp<string>
  bg?: ResponsiveProp<string>
  border?: ResponsiveProp<number | string>
  borderBottom?: ResponsiveProp<string | number>
  borderBottomColor?: ResponsiveProp<string>
  borderBottomLeftRadius?: ResponsiveProp<string | number>
  borderBottomRightRadius?: ResponsiveProp<string | number>
  borderBottomStyle?: ResponsiveProp<string>
  borderBottomWidth?: ResponsiveProp<string | number>
  borderColor?: ResponsiveProp<string>
  borderLeft?: ResponsiveProp<string | number>
  borderLeftColor?: ResponsiveProp<string>
  borderLeftStyle?: ResponsiveProp<string>
  borderLeftWidth?: ResponsiveProp<string | number>
  borderRadius?: ResponsiveProp<string | number>
  borderRight?: ResponsiveProp<string | number>
  borderRightColor?: ResponsiveProp<string>
  borderRightStyle?: ResponsiveProp<string>
  borderRightWidth?: ResponsiveProp<string | number>
  borderStyle?: ResponsiveProp<string>
  borderTop?: ResponsiveProp<string | number>
  borderTopColor?: ResponsiveProp<string>
  borderTopLeftRadius?: ResponsiveProp<string | number>
  borderTopRightRadius?: ResponsiveProp<string | number>
  borderTopStyle?: ResponsiveProp<string>
  borderTopWidth?: ResponsiveProp<string | number>
  borderWidth?: ResponsiveProp<string | number>
  bottom?: ResponsiveProp<string | number>
  boxShadow?: ResponsiveProp<number | string>
  boxSizing?: ResponsiveProp<CSS.Property.BoxSizing>
  color?: ResponsiveProp<string>
  columnGap?: ResponsiveProp<string | number>
  display?: ResponsiveProp<CSS.Property.Display>
  flex?: ResponsiveProp<CSS.Property.Flex<string>>
  flexBasis?: ResponsiveProp<CSS.Property.FlexBasis<number | string>>
  flexDirection?: ResponsiveProp<null | CSS.Property.FlexDirection>
  flexGrow?: ResponsiveProp<number | 'inherit' | 'initial' | 'unset'>
  flexShrink?: ResponsiveProp<number | 'inherit' | 'initial' | 'unset'>
  flexWrap?: ResponsiveProp<string>
  fontSize?: ResponsiveProp<number | string>
  fontStyle?: ResponsiveProp<CSS.Property.FontStyle>
  fontWeight?: ResponsiveProp<string | number>
  gap?: ResponsiveProp<string | number>
  gridArea?: ResponsiveProp<CSS.Property.GridArea>
  gridAutoColumns?: ResponsiveProp<CSS.Property.GridAutoColumns<number>>
  gridAutoFlow?: ResponsiveProp<CSS.Property.GridAutoFlow>
  gridAutoRows?: ResponsiveProp<CSS.Property.GridAutoRows<number>>
  gridColumn?: ResponsiveProp<CSS.Property.GridColumn>
  gridColumnGap?: ResponsiveProp<CSS.Property.GridColumnGap<number>>
  gridGap?: ResponsiveProp<CSS.Property.GridGap<number>>
  gridRow?: ResponsiveProp<CSS.Property.GridRow>
  gridRowGap?: ResponsiveProp<CSS.Property.GridRowGap<number>>
  gridTemplateAreas?: ResponsiveProp<CSS.Property.GridTemplateAreas>
  gridTemplateColumns?: ResponsiveProp<CSS.Property.GridTemplateColumns<number>>
  gridTemplateRows?: ResponsiveProp<CSS.Property.GridTemplateRows<number>>
  height?: ResponsiveProp<CSS.Property.Height<number>>
  justifyContent?: ResponsiveProp<CSS.Property.JustifyContent>
  justifySelf?: ResponsiveProp<CSS.Property.JustifySelf>
  left?: ResponsiveProp<CSS.Property.Left<number>>
  letterSpacing?: ResponsiveProp<CSS.Property.LetterSpacing<string>>
  lineHeight?: ResponsiveProp<CSS.Property.LineHeight<number>>
  m?: ResponsiveProp<CSS.Property.Margin<number>>
  margin?: ResponsiveProp<CSS.Property.Margin<number>>
  marginBottom?: ResponsiveProp<string | number>
  marginLeft?: ResponsiveProp<CSS.Property.Margin<number>>
  marginRight?: ResponsiveProp<CSS.Property.Margin<number>>
  marginTop?: ResponsiveProp<CSS.Property.Margin<number>>
  maxHeight?: ResponsiveProp<CSS.Property.MaxHeight<number>>
  maxWidth?: ResponsiveProp<CSS.Property.MaxWidth<number>>
  mb?: ResponsiveProp<CSS.Property.Margin<number>>
  minHeight?: ResponsiveProp<CSS.Property.MinHeight<number>>
  minWidth?: ResponsiveProp<CSS.Property.MinWidth<number>>
  ml?: ResponsiveProp<CSS.Property.Margin<number>>
  mr?: ResponsiveProp<CSS.Property.Margin<number>>
  mt?: ResponsiveProp<CSS.Property.Margin<number>>
  mx?: ResponsiveProp<CSS.Property.Margin<number>>
  my?: ResponsiveProp<CSS.Property.Margin<number>>
  opacity?: ResponsiveProp<CSS.Property.Opacity>
  overflow?: ResponsiveProp<CSS.Property.Overflow>
  overflowX?: ResponsiveProp<CSS.Property.OverflowX>
  overflowY?: ResponsiveProp<CSS.Property.OverflowY>
  padding?: ResponsiveProp<CSS.Property.Padding<number>>
  paddingBlock?: ResponsiveProp<CSS.Property.PaddingBlock<number>>
  paddingBottom?: ResponsiveProp<CSS.Property.Padding<number>>
  paddingInline?: ResponsiveProp<CSS.Property.PaddingInline<number>>
  paddingLeft?: ResponsiveProp<CSS.Property.Padding<number>>
  paddingRight?: ResponsiveProp<CSS.Property.Padding<number>>
  paddingTop?: ResponsiveProp<CSS.Property.Padding<number>>
  pb?: ResponsiveProp<CSS.Property.Padding<number>>
  pl?: ResponsiveProp<CSS.Property.Padding<number>>
  placeItems?: ResponsiveProp<
    CSS.Property.AlignItems | CSS.Property.JustifyItems
  >
  position?: ResponsiveProp<CSS.Property.Position>
  p?: ResponsiveProp<CSS.Property.Padding<number>>
  pr?: ResponsiveProp<CSS.Property.Padding<number>>
  pt?: ResponsiveProp<CSS.Property.Padding<number>>
  px?: ResponsiveProp<CSS.Property.Padding<number>>
  py?: ResponsiveProp<CSS.Property.Padding<number>>
  right?: ResponsiveProp<CSS.Property.Right<number>>
  rowGap?: ResponsiveProp<string | number>
  textAlign?: ResponsiveProp<CSS.Property.TextAlign>
  textDecoration?: ResponsiveProp<CSS.Property.TextDecoration<number>>
  textTransform?: ResponsiveProp<CSS.Property.TextTransform>
  top?: ResponsiveProp<CSS.Property.Top<number>>
  type?: ResponsiveProp<string>
  verticalAlign?: ResponsiveProp<CSS.Property.VerticalAlign<number>>
  visibility?: ResponsiveProp<CSS.Property.Visibility>
  whiteSpace?: ResponsiveProp<CSS.Property.WhiteSpace>
  width?: ResponsiveProp<CSS.Property.Width<number>>
  zIndex?: ResponsiveProp<CSS.Property.ZIndex>
}

export type StyledPropsCss = Simplify<
  Omit<CSS.Properties<string | number>, keyof StyledPropsProps>
> &
  StyledPropsProps & {
    [key: Selectors]: StyledPropsCss
    textWrap?: string
    a?: StyledPropsCss
    p?: StyledPropsCss | StyledPropsProps['p']
    h1?: StyledPropsCss
    h2?: StyledPropsCss
    h3?: StyledPropsCss
    h4?: StyledPropsCss
    h5?: StyledPropsCss
    h6?: StyledPropsCss
    blockquote?: StyledPropsCss
    code?: StyledPropsCss
    img?: StyledPropsCss
    pre?: StyledPropsCss
    hr?: StyledPropsCss
    ol?: StyledPropsCss
    ul?: StyledPropsCss
    li?: StyledPropsCss
    table?: StyledPropsCss
    tr?: StyledPropsCss
    th?: StyledPropsCss
    td?: StyledPropsCss
    strong?: StyledPropsCss
    em?: StyledPropsCss
    del?: StyledPropsCss
    sub?: StyledPropsCss
    sup?: StyledPropsCss
    svg?: StyledPropsCss
    path?: StyledPropsCss
  }

export type StyledProps<El = Element> = React.HTMLAttributes<El> &
  StyledPropsProps &
  HTMLProps<El> &
  HTMLAnchorProps & {
    // This should really move to its own interface, something like ReactHTMLProps or something
    dangerouslySetInnerHTML?: { __html: string | TrustedHTML }
    // We're good with any here
    // eslint-disable-next-line
    ref?: React.Ref<any>
    as?: string | React.ReactNode | React.ComponentType
    children?: React.ReactNode
    className?: string
    css?: StyledPropsCss
    cursor?: ResponsiveProp<CSS.Property.Cursor>
    [key: `data-${string}`]: string
  }

export {
  compose,
  space,
  typography,
  color,
  layout,
  flexbox,
  grid,
  border,
  background,
  position,
  shadow,
  variant,
  system,
}

// Add css properties that are not supported by styled-system out of the box
export const textTransform = system({
  textTransform: true,
})
export const textDecoration = system({
  textDecoration: true,
})
export const whiteSpace = system({
  whiteSpace: true,
})
export const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
  rowGap: {
    property: 'rowGap',
    scale: 'space',
  },
  columnGap: {
    property: 'columnGap',
    scale: 'space',
  },
})
export const paddingBlock = system({
  paddingBlock: { property: 'paddingBlock', scale: 'space' },
})
export const paddingInline = system({
  paddingInline: { property: 'paddingInline', scale: 'space' },
})
export const placeItems = system({
  placeItems: {
    property: 'placeItems',
  },
})
export const visibility = system({
  visibility: {
    property: 'visibility',
  },
})

export const css = (props: AnyObject): AnyObject | null => {
  if (props.css) {
    return styledSystemCss(props.css)
  }
  return null
}

export const cursor = system({
  cursor: true,
})

export const propTransformer = compose(
  space,
  typography,
  color,
  layout,
  flexbox,
  gap,
  grid,
  border,
  background,
  paddingBlock,
  paddingInline,
  placeItems,
  position,
  shadow,
  textTransform,
  textDecoration,
  cursor,
  whiteSpace,
  letterSpacing,
  visibility
)
