import React from 'react'
import { AnyObject } from '@voltus/types'
import { Box } from '../components/Box'
import { IconButtonContext } from '../components/IconButton'
import { StyledProps } from '../utils/styledSystem'

import Alert from './Alert'
import ArrowNarrowDown from './ArrowNarrowDown'
import ArrowNarrowUp from './ArrowNarrowUp'
import BackArrow from './BackArrow'
import Bell from './Bell'
import BellPlus from './BellPlus'
import BellRinging from './BellRinging'
import Building from './Building'
import Calendar from './Calendar'
import ChartPie from './ChartPie'
import Checkmark from './Checkmark'
import Chevron from './Chevron'
import ChevronLeft from './ChevronLeft'
import ChevronRight from './ChevronRight'
import CircleA from './CircleA'
import CircleCheck from './CircleCheck'
import CircleExclamation from './CircleExclamation'
import CircleInfo from './CircleInfo'
import CircleX from './CircleX'
import CircleXFilled from './CircleXFilled'
import Clock from './Clock'
import ClockArrow from './ClockArrow'
import ClockCheck from './ClockCheck'
import Close from './Close'
import Coal from './Coal'
import Copy from './Copy'
import Dismiss from './Dismiss'
import Dot from './Dot'
import Dots from './Dots'
import Download from './Download'
import Edit from './Edit'
import Error from './Error'
import Expand from './Expand'
import Eye from './Eye'
import EyeClosed from './EyeClosed'
import EyeOpen from './EyeOpen'
import File from './File'
import FilledAdd from './FilledAdd'
import Filter from './Filter'
import FlagCanada from './FlagCanada'
import FlagUsa from './FlagUsa'
import Folder from './Folder'
import FolderZip from './FolderZip'
import Forest from './Forest'
import ForwardArrow from './ForwardArrow'
import Gas from './Gas'
import Grid from './Grid'
import Home from './Home'
import Info from './Info'
import LayoutList from './LayoutList'
import LayoutPushLeft from './LayoutPushLeft'
import LayoutPushRight from './LayoutPushRight'
import LinkTo from './LinkTo'
import List from './List'
import Logo from './Logo'
import Mail from './Mail'
import Message from './Message'
import Minimize from './Minimize'
import NA from './NA'
import Notification from './Notification'
import Oil from './Oil'
import Pause from './Pause'
import PendingBalances from './PendingBalances'
import Phone from './Phone'
import Pin from './Pin'
import Play from './Play'
import PlaySkipBack from './PlaySkipBack'
import PlaySkipForward from './PlaySkipForward'
import Plus from './Plus'
import Profile from './Profile'
import PuzzlePiece from './PuzzlePiece'
import QuestionMark from './QuestionMark'
import Refresh from './Refresh'
import RefreshDoubleArrow from './RefreshDoubleArrow'
import Remove from './Remove'
import Search from './Search'
import Seedling from './Seedling'
import Send from './Send'
import Settings from './Settings'
import Source from './Source'
import Square from './Square'
import Success from './Success'
import Template from './Template'
import Trash from './Trash'
import TriangleAlert from './TriangleAlert'
import TriangleCircleSquare from './TriangleCircleSquare'
import User from './User'
import Users from './Users'
import Vehicle from './Vehicle'
import WorldBolt from './WorldBolt'

// One of the icon sources: https://github.com/tabler/tabler-icons

const Icons = {
  Alert,
  ArrowNarrowDown,
  ArrowNarrowUp,
  BackArrow,
  Bell,
  BellPlus,
  BellRinging,
  Building,
  Calendar,
  ChartPie,
  Checkmark,
  Chevron,
  ChevronLeft,
  ChevronRight,
  CircleA,
  CircleCheck,
  CircleExclamation,
  CircleInfo,
  CircleX,
  CircleXFilled,
  Clock,
  ClockArrow,
  ClockCheck,
  Close,
  Coal,
  Copy,
  Dismiss,
  Dot,
  Dots,
  Download,
  Edit,
  Error,
  Expand,
  Eye,
  EyeClosed,
  EyeOpen,
  File,
  FilledAdd,
  Filter,
  FlagCanada,
  FlagUsa,
  Folder,
  FolderZip,
  Forest,
  ForwardArrow,
  Gas,
  Grid,
  Home,
  Info,
  LayoutList,
  LayoutPushLeft,
  LayoutPushRight,
  LinkTo,
  List,
  Logo,
  Mail,
  Message,
  Minimize,
  NA,
  Notification,
  Oil,
  Pause,
  PendingBalances,
  Phone,
  Pin,
  Play,
  PlaySkipBack,
  PlaySkipForward,
  Plus,
  Profile,
  PuzzlePiece,
  QuestionMark,
  Refresh,
  RefreshDoubleArrow,
  Remove,
  Search,
  Seedling,
  Send,
  Settings,
  Source,
  Square,
  Success,
  Template,
  Trash,
  TriangleAlert,
  TriangleCircleSquare,
  User,
  Users,
  Vehicle,
  WorldBolt,
}

const DEFAULT_ICON_WIDTH = 20
const DEFAULT_ICON_HEIGHT = 20

export type StyledIconsProps = StyledProps & {
  activeColor?: string
  isChangingOnHover?: boolean
  disabled?: boolean
  color?: string
  secondaryColor?: string
  isActive?: boolean
  width?: number | string
  height?: number | string
  css?: AnyObject
}

type MakeIconConfig = {
  name: string
  defaultWidth?: number | string
  defaultHeight?: number | string
  isStrokeBased?: boolean
  defaultColor?: string
}

const MakeIcon = ({
  name,
  defaultWidth = DEFAULT_ICON_WIDTH,
  defaultHeight = DEFAULT_ICON_HEIGHT,
  isStrokeBased = false,
  defaultColor,
}: MakeIconConfig): React.ForwardRefExoticComponent<
  React.RefAttributes<HTMLElement> & StyledIconsProps
> => {
  const IconComponent = Icons[name]

  const Comp = React.forwardRef<HTMLElement>(
    (
      {
        color,
        secondaryColor,
        isActive,
        isChangingOnHover = true,
        width = defaultWidth,
        height = defaultHeight,
        disabled = false,
        css = {},
        ...props
      }: StyledIconsProps,
      ref
    ) => {
      const iconBtnContext = React.useContext(IconButtonContext)
      const [isHoveringLocal, setIsHovering] = React.useState(false)
      const isHovering = iconBtnContext?.hovering || isHoveringLocal
      let currentColor =
        secondaryColor && (isActive || (isChangingOnHover && isHovering))
          ? secondaryColor
          : color
      currentColor = currentColor ?? defaultColor
      if (disabled) {
        currentColor = 'grays.20'
      }
      return (
        <Box
          ref={ref}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          width={width}
          height={height}
          {...props}
          css={{
            ...css,
            '*': isStrokeBased
              ? { stroke: currentColor }
              : { fill: currentColor },
          }}
        >
          <IconComponent
            style={{
              width,
              height,
            }}
          />
        </Box>
      )
    }
  )
  Comp.displayName = `StyledIcon[${name}]`

  return Comp
}

const StyledIcons = {
  Alert: MakeIcon({ name: 'Alert' }),
  ArrowNarrowDown: MakeIcon({ name: 'ArrowNarrowDown', isStrokeBased: true }),
  ArrowNarrowUp: MakeIcon({ name: 'ArrowNarrowUp', isStrokeBased: true }),
  BackArrow: MakeIcon({ name: 'BackArrow' }),
  Bell: MakeIcon({ name: 'Bell', isStrokeBased: true }),
  BellPlus: MakeIcon({ name: 'BellPlus', isStrokeBased: true }),
  BellRinging: MakeIcon({ name: 'BellRinging', isStrokeBased: true }),
  Checkmark: MakeIcon({ name: 'Checkmark' }),
  Chevron: MakeIcon({ name: 'Chevron', defaultWidth: 12 }),
  ChevronLeft: MakeIcon({ name: 'ChevronLeft', isStrokeBased: true }),
  ChevronRight: MakeIcon({ name: 'ChevronRight', isStrokeBased: true }),
  ChartPie: MakeIcon({ name: 'ChartPie' }),
  CircleA: MakeIcon({ name: 'CircleA', isStrokeBased: true }),
  CircleCheck: MakeIcon({
    name: 'CircleCheck',
    isStrokeBased: true,
  }),
  TriangleCircleSquare: MakeIcon({
    name: 'TriangleCircleSquare',
    isStrokeBased: true,
  }),
  LayoutPushLeft: MakeIcon({
    name: 'LayoutPushLeft',
    defaultHeight: 16,
    defaultWidth: 16,
    isStrokeBased: true,
  }),
  TriangleAlert: MakeIcon({
    name: 'TriangleAlert',
    isStrokeBased: true,
  }),
  LayoutPushRight: MakeIcon({
    name: 'LayoutPushRight',
    defaultHeight: 16,
    defaultWidth: 16,
    isStrokeBased: true,
  }),
  LayoutList: MakeIcon({
    name: 'LayoutList',
    defaultHeight: 16,
    defaultWidth: 16,
  }),
  Square: MakeIcon({
    name: 'Square',
    defaultHeight: 16,
    defaultWidth: 16,
  }),
  CircleInfo: MakeIcon({
    name: 'CircleInfo',
    isStrokeBased: true,
    defaultColor: 'textColor.main',
  }),
  CircleX: MakeIcon({
    name: 'CircleX',
    isStrokeBased: true,
  }),
  CircleXFilled: MakeIcon({
    name: 'CircleXFilled',
    defaultHeight: 30,
    defaultWidth: 30,
  }),
  Clock: MakeIcon({ name: 'Clock', isStrokeBased: true }),
  ClockCheck: MakeIcon({ name: 'ClockCheck', isStrokeBased: true }),
  ClockArrow: MakeIcon({ name: 'ClockArrow', isStrokeBased: true }),
  Close: MakeIcon({ name: 'Close' }),
  Coal: MakeIcon({ name: 'Coal', defaultHeight: 50, defaultWidth: 50 }),
  Copy: MakeIcon({
    name: 'Copy',
    isStrokeBased: true,
    defaultHeight: 16,
    defaultWidth: 16,
  }),
  FilledAdd: MakeIcon({ name: 'FilledAdd' }),
  Dismiss: MakeIcon({ name: 'Dismiss', defaultWidth: 14, defaultHeight: 14 }),
  Download: MakeIcon({ name: 'Download', defaultWidth: 15, defaultHeight: 18 }),
  Edit: MakeIcon({ name: 'Edit', defaultWidth: 14, defaultHeight: 14 }),
  Error: MakeIcon({ name: 'Error', isStrokeBased: true }),
  CircleExclamation: MakeIcon({
    name: 'CircleExclamation',
    isStrokeBased: true,
  }),
  Eye: MakeIcon({ name: 'Eye' }),
  EyeOpen: MakeIcon({ name: 'EyeOpen', isStrokeBased: true }),
  EyeClosed: MakeIcon({ name: 'EyeClosed', isStrokeBased: true }),
  Expand: MakeIcon({ name: 'Expand', isStrokeBased: true }),
  Refresh: MakeIcon({ name: 'Refresh', isStrokeBased: true }),
  RefreshDoubleArrow: MakeIcon({
    name: 'RefreshDoubleArrow',
    defaultHeight: 16,
    defaultWidth: 16,
    isStrokeBased: true,
  }),
  Building: MakeIcon({ name: 'Building', isStrokeBased: true }),
  File: MakeIcon({ name: 'File', isStrokeBased: true }),
  Filter: MakeIcon({ name: 'Filter', isStrokeBased: true }),
  Folder: MakeIcon({ name: 'Folder', isStrokeBased: true }),
  FolderZip: MakeIcon({ name: 'FolderZip', isStrokeBased: true }),
  Forest: MakeIcon({ name: 'Forest', defaultHeight: 45, defaultWidth: 50 }),
  ForwardArrow: MakeIcon({ name: 'ForwardArrow' }),
  Gas: MakeIcon({ name: 'Gas', defaultHeight: 50, defaultWidth: 50 }),
  Grid: MakeIcon({ name: 'Grid' }),
  Home: MakeIcon({ name: 'Home', isStrokeBased: true }),
  Info: MakeIcon({ name: 'Info' }),
  LinkTo: MakeIcon({ name: 'LinkTo' }),
  List: MakeIcon({ name: 'List', isStrokeBased: true }),
  Logo: MakeIcon({ name: 'Logo', isStrokeBased: true }),
  Mail: MakeIcon({ name: 'Mail', isStrokeBased: true }),
  NA: MakeIcon({ name: 'NA', isStrokeBased: true }),
  Oil: MakeIcon({ name: 'Oil', defaultHeight: 50, defaultWidth: 50 }),
  Pause: MakeIcon({ name: 'Pause', isStrokeBased: true }),
  Play: MakeIcon({ name: 'Play', isStrokeBased: true }),
  PlaySkipBack: MakeIcon({ name: 'PlaySkipBack', isStrokeBased: true }),
  PlaySkipForward: MakeIcon({ name: 'PlaySkipForward', isStrokeBased: true }),
  Pin: MakeIcon({ name: 'Pin' }),
  Profile: MakeIcon({ name: 'Profile' }),
  Remove: MakeIcon({ name: 'Remove' }),
  Source: MakeIcon({ name: 'Source' }),
  Search: MakeIcon({ name: 'Search', isStrokeBased: true }),
  Seedling: MakeIcon({ name: 'Seedling', defaultHeight: 50, defaultWidth: 50 }),
  Settings: MakeIcon({ name: 'Settings', isStrokeBased: true }),
  Success: MakeIcon({ name: 'Success', isStrokeBased: true }),
  Send: MakeIcon({ name: 'Send' }),
  WorldBolt: MakeIcon({ name: 'WorldBolt', isStrokeBased: true }),
  QuestionMark: MakeIcon({
    name: 'QuestionMark',
    defaultWidth: 16,
    defaultHeight: 16,
  }),
  Template: MakeIcon({ name: 'Template', isStrokeBased: true }),
  Trash: MakeIcon({ name: 'Trash', isStrokeBased: true }),
  PendingBalances: MakeIcon({ name: 'PendingBalances' }),
  PuzzlePiece: MakeIcon({ name: 'PuzzlePiece' }),
  User: MakeIcon({ name: 'User' }),
  Users: MakeIcon({ name: 'Users' }),
  Vehicle: MakeIcon({ name: 'Vehicle', defaultHeight: 50, defaultWidth: 50 }),
  Dot: MakeIcon({ name: 'Dot' }),
  Dots: MakeIcon({ name: 'Dots', isStrokeBased: true }),
  Phone: MakeIcon({ name: 'Phone', isStrokeBased: true }),
  Plus: MakeIcon({ name: 'Plus', isStrokeBased: true }),
  Calendar: MakeIcon({ name: 'Calendar' }),
  Message: MakeIcon({ name: 'Message', isStrokeBased: true }),
  Minimize: MakeIcon({ name: 'Minimize', isStrokeBased: true }),
  Notification: MakeIcon({ name: 'Notification' }),
}

export { Icons, StyledIcons }
