export const FETCHING_ICON = 'fetching-icon'
export const ACTIVE_DISPATCHES_NAV_ITEM = 'active-dispatch-nav-item'
export const VOC_PORTFOLIO_ROW_ITEM = 'portfolio-row-item'
export const VOC_PARTICIPATION_BAR_CHART =
  'portfolio-row-participation-bar-chart'
export const VOC_PERFORMANCE_BAR_CHART = 'portfolio-row-performance-bar-chart'
export const VOC_OVERVIEW_DISPATCH_GROUP_ROW = 'voc-overview-dispatch-group-row'
export const VOC_DETAIL_PAGE_SITE_ROW = 'voc-detail-page-site-row'
export const VOC_DETAIL_PAGE_SITE_CARD = 'voc-detail-page-site-card'
export const VOC_PARTICIPATION_SUMMARY_BAR_CHART_CONFIRMED =
  'voc-participation-summary-bar-chart-confirmed'
export const VOC_PARTICIPATION_SUMMARY_BAR_CHART_REJECTED =
  'voc-participation-summary-bar-chart-rejected'
export const VOC_PARTICIPATION_SUMMARY_BAR_CHART_NOT_RESPONDED =
  'voc-participation-summary-bar-chart-not-responded'
export const VOC_PERFORMANCE_SUMMARY_BAR_CHART =
  'voc-performance-summary-bar-chart'
export const VOC_SITE_LIST_CURRENT = 'voc-site-list-current'
export const VOC_SITE_LIST_DISPATCH = 'voc-site-list-dispatch'
export const VOC_SITE_LIST_LIST = 'voc-site-list-list-view'
export const VOC_SITE_LIST_GRID = 'voc-site-list-grid-view'

export const VOC_ADMIN_SITE_VIEW = 'voc-admin-site-view'
export const VOC_ADMIN_SITE_CONTROLS_TYPE = 'voc-admin-site-controls-type'
export const VOC_ADMIN_SITE_STATUS = 'voc-admin-site-status'
export const VOC_ADMIN_SITE_ASSIGNMENT = 'voc-admin-site-assignment'
export const VOC_ADMIN_SITE_SORT = 'voc-admin-site-sort'
export const VOC_ADMIN_DESKTOP_SITE_ROW = 'voc-admin-desk-site-row'
export const VOC_ADMIN_ASSIGN_SITE_MODAL = 'voc-admin-assign-site-modal'
export const VOC_ADMIN_ASSIGN_SITE_DROPDOWN = 'voc-admin-assign-site-dropdown'
export const VOC_ADMIN_ASSIGN_SITE_ASSIGN_BUTTON =
  'voc-admin-assign-site-assign-button'
export const VOC_ADMIN_ASSIGN_SITE_UNASSIGN_BUTTON =
  'voc-admin-assign-site-unassign-button'
export const VOC_ADMIN_ACK_STATUS_DROPDOWN = 'voc-admin-ack-status-dropdown'
export const VOC_DISPATCH_PROGRAM_DROPDOWN = 'voc-dispatch-program-dropdown'
export const VOC_DISPATCH_EVENT_TYPE_DROPDOWN =
  'voc-dispatch-event-type-dropdown'
export const VOC_DISPATCH_STATUS_DROPDOWN = 'voc-dispatch-status-dropdown'
export const VOC_DISPATCH_STARTING_IN_TIME_DROPDOWN =
  'voc-dispatch-starting-in-time-dropdown'
export const VOC_DISPATCH_ENDING_IN_TIME_DROPDOWN =
  'voc-dispatch-ending-in-time-dropdown'
export const VOC_DISPATCH_STARTING_IN_TIME_CHECKBOX =
  'voc-dispatch-starting-in-time-checkbox'
export const VOC_DISPATCH_ENDING_IN_TIME_CHECKBOX =
  'voc-dispatch-ending-in-time-checkbox'

export const ADMIN_NAV_ITEM = 'admin-nav-item'
export const REALTIME_DASH_NAV_ITEM = 'realtime-dash-nav-item'
export const BOARDS_NAV_ITEM = 'dashboards-nav-item'
export const CASHDASH_NAV_ITEM = 'cashdash-nav-item'
export const DISPATCHES_NAV_ITEM = 'dispatches-nav-item'
export const SITES_DASH_NAV_ITEM = 'sites-dash-nav-item'
export const PROFILE_NAV_ITEM = 'profile-nav-item'

export const FORBIDDEN_PAGE_LOGIN_LINK = 'forbidden-page-login-link'
export const FORBIDDEN_PAGE_LOGIN_TEXT_LINK = 'forbidden-page-login-text-link'

export const REALTIME_ENERGY_DRAWER_BUTTON = 'realtime-energy-drawer-button'

export const EARNINGS_HEATMAP_CALENDAR_DAY = 'earnings-heatmap-calendar-day'
export const VIRTUALIZED_TABLE_ROW = 'virtualized-table-row'
export const VIRTUALIZED_TABLE_CHECKBOX = (index: number): string =>
  `virtualized-table-checkbox-${index}`
export const VIRTUALIZED_TABLE_SELECT_ALL =
  'virtualized-table-checkbox-select-all'

export const SORTABLE_TABLE_CHECKBOX = (index: number): string =>
  `sortable-table-checkbox-${index}`
export const SORTABLE_TABLE_SELECT_ALL = 'sortable-table-checkbox-select-all'

export const CSV_MODAL_DOWNLOAD_BUTTON = 'csv-modal-download-button'
export const HISTORIC_AVERAGE_PERFORMANCE_CARD =
  'historic-average-performance-card'

export const ACTIVITY_INDICATOR = 'ActivityIndicator'
export const PROGRAM_DETAILS_MODAL = 'ProgramDetailsModal'
export const MODAL = 'Modal'

export const MARKET_RESOURCE_DETAIL_HEADLINE = 'market-resource-detail-headline'
export const MARKET_RESOURCES_DISABLE_MODAL = 'market-resources-disable-modal'
export const MARKET_RESOURCES_DISQUALIFY_MODAL =
  'market-resources-disqualify-modal'
export const MARKET_RESOURCES_ADD_MODAL = 'market-resources-add-modal'
export const MARKET_RESOURCES_ADD_MODAL_ERROR =
  'market-resources-add-modal-error'
export const MARKET_RESOURCES_ADD_MODAL_SEARCHING =
  'market-resources-add-modal-searching'
export const MARKET_RESOURCES_ADD_MODAL_CONFIRMING =
  'market-resources-add-modal-confirming'

export const CARBON_EXPAND_BUTTON = 'carbonExpandButton'
export const CARBON_MODAL_BUTTON = 'carbonModalButton'
export const CARBON_DOWNLOAD_PDF_BUTTON = 'carbonDownloadPdfButton'
export const CARBON_DOWNLOAD_MODAL_BUTTON = 'carbonDownloadModalButton'
export const CARBON_DATE_DROPDOWN = 'carbonDateDropdown'
